@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #8A2BE2;
  --secondary-color: #4B0082;
}

body {
  font-family: 'Roboto', sans-serif;
}

.dark {
  @apply bg-gray-900 text-white;
}

@layer base {
  body {
    @apply bg-gray-50 text-gray-900 dark:bg-gray-900 dark:text-white;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-semibold text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
  .btn-primary {
    @apply bg-purple-500 text-white hover:bg-purple-600 focus:ring-purple-500;
  }
}